.asset-heading-main {
    font-family: "Rubik", sans-serif !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 32px !important;
    /* 133.333% */
    letter-spacing: 0.24px !important;
    color: #3C3E49 !important;

}

.asset-heading-sub {
    color: #595959 !important;
    font-family: "Rubik", sans-serif !important;
    font-size: 16px !important;
    margin-top: 4px !important;
    letter-spacing: 0 !important;
}

.assets-search-select {
    background: #F3EBFF !important;
    padding: 11 !important;
}

.asset-title-wrapper .info-wrapper {
    margin-left: 8px;
    position: relative;
    display: inline-block;
    top: 3px;
}

.info-tooltip .MuiTooltip-tooltip {
    max-width: 328px !important;
    box-shadow: 0px 12px 30px 0px rgba(101, 31, 255, 0.1) !important;
    background: #FFFFFF !important;
    border-radius: 3px !important;
    padding: 0 !important;
    color: unset !important;
    font-family: "Rubik", sans-serif !important;
    margin: 12px 0 !important;
    padding: 16px !important;
    transform: none !important;
    transition: none !important;
}

.info-tooltip .MuiTooltip-tooltip::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;    
    border-top: 8px solid #FFFFFF;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
}

.info-tooltip .MuiTooltip-tooltip .info-heading {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #333333;
    margin-bottom: 4px;
}

.info-tooltip .MuiTooltip-tooltip .info-desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #484848;
}

.asset-title-wrapper .info-wrapper .info-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
    z-index: 9999;
    position: relative;
}

.asset-title {
    font-family: 'Rubik', sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    color: #3C3E49 !important;
    /* white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important; */
}

.asset-type {
    font-family: 'Rubik', sans-serif !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0.01em !important;
    text-align: left !important;
    color: #3C3E49 !important;
    text-transform: capitalize !important;
    width: auto;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 4px;
    margin-bottom: 4px !important;
    background-color: #F5F5F5;
}

.aseet-download-buton {
    border: 0 !important;
    padding: 16px, 24px, 16px, 24px !important;
    border-radius: 8px !important;
    gap: 4px !important;
    background: #F3EBFF !important;
    text-transform: none !important;
    color: #262626 !important;
    font-weight: 500 !important;
}

.asset-card {
    /* border: 1px solid #DFE0E6 !important; */
    border-radius: 8px !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.15) !important;
}

.asset-card:hover {
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 3px 24px 0 rgba(0, 0, 0, 0.15) !important;
}

.asset-card .asset-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.asset-card .asset-image.has-light-bg {
    background-color: #F3EBFF;
    background-size: contain;
}

.asset-card .asset-image img {
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
}

.asset-card.small-thumb .asset-image {
    background-size: contain;
}

.asset-card.small-thumb .asset-image img {
    height: auto;
    width: auto;
}

.asset-card .asset-image.has-light-bg img {
    max-height: 65px;
    width: auto;
}

.asset-actions {
    justify-content: flex-start;
    padding: 0px 12px 13px !important;
    /* border-top: 1px solid #DFE0E6; */
    margin-top: auto;
}

.asset-actions .download-btn {
    min-width: 116px;
    width: 100%;
    border: 2px solid #fff;
    transition: all 0.3s ease-in-out;
}

.asset-actions .download-btn:hover {
    border: 2px solid #B388FF;
    transition: all 0.3s ease-in-out;
}

.mr-10 {
    margin-right: 10px;
}

/* .asset-image {
    background-size: 100% 100% !important;
} */

.search-input {
    background-color: #FFF !important;
    width: 304px !important;
}

.file-type-button {
    text-transform: none !important;
    background: #F3EBFF !important;
    width: 124px !important;
}

.sort-button {
    text-transform: none !important;
}

.sort-container {
    display: flex;
    justify-content: flex-end;
}

.select-container {
    display: flex;
    justify-content: center;
    margin-right: 12px;
}

.asset-select-option-text {
    width: 100% !important;
    text-align: left !important;
    /* text-transform: capitalize; */
    cursor: pointer;
}

.text-transform {
    text-transform: capitalize;
}

.preview-main-container {
    background: #F8F9FA !important;
}

.asset-main-row {
    height: calc(100vh - 96px);
    max-height: calc(100vh - 96px);
}

.asset-main-row .asset-preview-col {
    flex: 0 0 78%;
    max-width: 78%;
}

.asset-main-row .asset-info-col {
    flex: 0 0 22%;
    max-width: 22%;
}

.preview-side-container {
    background: #FFFFFF !important;
    height: 100%;
    max-height: calc(100vh - 96px);
    border-left: 1px solid #ECEDF0;
}

.preview-side-container .MuiOutlinedInput-notchedOutline {
    border-color: #C0C3CE !important;
    border-width: 1px !important;
}

.preview-side-container .side-content-wrap {
    padding: 24px;
    height: 100%;
    max-height: calc(100% - 83px);
    overflow-y: auto;
}

.preview-side-container .side-content-wrap .side-content-desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #595959;
    margin-top: 16px;
}

.preview-side-container .side-content-wrap .side-content-header {
    display: flex;
    justify-content: flex-end;
}

.preview-side-container .side-content-wrap .side-content-header .close-btn {
    padding: 3px;
    background-color: transparent !important;
    position: relative;
    top: -4px;
    right: -4px;
}

.preview-side-container .side-content-wrap .side-content-header .close-btn img {
    height: 20px;
    width: 20px;
}

.preview-side-container .side-content-wrap .side-content-title-block {
    padding-top: 40px;
}

.preview-side-container .side-content-wrap .share-wrapper {
    margin-top: 16px;
}

.preview-side-container .custom-btn {
    height: 48px !important;
    padding: 6px 16px !important
}

.m-10 {
    margin: 10px;
}

.m-24 {
    margin: 24px;
}

.side-container-heading {
    font-family: 'Rubik', sans-serif !important;
    font-size: 22px !important;
    font-weight: 500 !important;
    line-height: 32px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    color: var(--blue-grey-10, var(--blue-grey-10, #3C3E49)) !important;
}

.file-info-list {
    padding: 16px 0 10px 0;
    margin-top: 16px;
    border-top: 1px solid #DFE0E6;
}

.file-info-list .file-info-item {
    margin: 0 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.file-info-list .file-info-item:last-child {
    margin: 0;
}

.file-info-list .file-info-heading {
    font-family: 'Rubik', sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 0.14px !important;
    font-style: normal !important;
    text-align: left !important;
    color: #3C3E49 !important;
    margin: 0 0 16px !important;
}

.file-info-list .file-info-item .file-info-text p {
    font-family: 'Rubik', sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #8C8C8C !important;
}

.file-info-list .file-info-item .file-value-text p {
    color: #595959 !important;
    text-align: right !important;
    font-family: 'Rubik', sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    flex: 1;
    padding-left: 5px;
}

.p-24 {
    padding: 24 !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mt-32 {
    margin-top: 32px !important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.Carousel .CarouselItem {
    height: 100% !important;
    max-height: calc(100vh -96px);
}

[class$="Carousel-buttonWrapper-"] {
    top: 43% !important;
}

[class$="Carousel-buttonWrapper-"] .MuiButtonBase-root {
    padding: 9px !important;
}

[class$="Carousel-buttonWrapper-"] .MuiButtonBase-root svg {
    height: 30px;
    width: 30px;
}

[class$="Carousel-next-"] {
    right: 16px;
}

[class$="Carousel-prev-"] {
    left: 16px;
}

.Carousel .video-wrap video {
    max-height: 408px;
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.Carousel .image-wrap {
    width: 814px;
    height: 100%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: inherit !important;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Carousel .image-wrap img {
    max-width: 100%;
    max-height: 100%;
}

.react-pdf__Document {
    overflow: auto !important;
    height: calc(100% - 60px) !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.react-pdf__Document::-webkit-scrollbar {
    display: none !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.asset-single-download-buton {
    border: 0 !important;
    padding: 8px 16px !important;
    border-radius: 8px !important;
    gap: 4px !important;
    text-transform: none !important;
    background: #00E676 !important;
}

.a-download-button {
    position: fixed;
    bottom: 32px;
    right: 32;
}

.asset-download-text {
    color: var(--true-grey-9, #262626) !important;
    font-family: 'Rubik', sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 0.14px !important;
}

.assets-count-text {
    color: var(--base-blue-grey-9, #676B7E) !important;
    font-family: 'Rubik', sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: 0.14px !important;
}

.grid-even-asset {
    margin: 0 -12px -24px;
    width: calc(100% + 24px) !important;
}

.grid-even-asset .card-wrap {
    padding: 0 12px;
    margin-bottom: 24px;

}

.grid-even-asset .card-wrap .card-content-wrap {
    padding: 13px 11px 9px !important;
}

.stages-menu-dropdown .MuiMenu-paper {
    max-height: 200px;
}

.MuiMenuItem-root.menu-item-text {
    color: var(--blue-grey-10, var(--base-blue-grey-10, #3C3E49)) !important;
    font-family: 'Rubik', sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1 !important;
    letter-spacing: 0.14px !important;
    width: 180px !important;
    max-width: 220px !important;
    display: flex !important;
    padding: 14px 32px 14px 16px !important;
    align-items: center !important;
}

.MuiMenuItem-root.menu-item-text.title-menu-item {
    font-weight: 500 !important;
}

.MuiMenuItem-root.menu-item-text span {
    font-size: 10px !important;
    line-height: 14px !important;
    font-weight: 500 !important;
    display: block !important;
}

.MuiMenuItem-root.menu-item-text .menu-item-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.date-range-menu-paper {
    box-shadow: 0px 2px 4px 0px #00000026 !important;
}

.menu-item-disabled-text {
    color: var(--blue-grey-8, var(--base-blue-grey-8, #83889E)) !important;
    font-family: 'Rubik', sans-serif !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    letter-spacing: 1px !important;
    text-transform: uppercase;
    width: 180px !important;
    max-width: 220px !important;
    display: flex !important;
    padding: 12px 16px !important;
    align-items: center !important;
    gap: 8px !important;
    align-self: stretch !important;
}

.MuiMenuItem-root.menu-item-text:hover {
    background: #F3EBFF !important;
}

.MuiList-root.custom-popover-list {
    padding: 8px !important;
}

.MuiListItem-root.list-item-text {
    color: #3C3E49 !important;
    font-family: 'Rubik', sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1 !important;
    letter-spacing: 0.14px !important;
    width: 100% !important;
    padding: 8px 16px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    border-radius: 8px !important;
    cursor: pointer !important;
}

.MuiListItem-root.list-item-text span {
    font-size: 10px !important;
    line-height: 14px !important;
    font-weight: 500 !important;
    display: block !important;
}

.MuiListItem-root.list-item-text:hover {
    background: #F3EBFF !important;
}

.share-text {
    color: var(--1, #000) !important;
    font-family: 'Rubik', sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 0.14px !important;
}

.asset-sort-text {
    display: flex !important;
    padding: 3px 6px 2px 0 !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 4px !important;
    color: var(--blue-grey-9, var(--blue-grey-9, #676B7E)) !important;
    font-family: "Rubik", sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    line-height: 20px !important;
    letter-spacing: 0.14px !important;
    font-weight: 400 !important;
}

.share-block-wrap {
    padding: 16px 0;
}

.share-input {
    height: 60px !important;
}

.share-input .MuiOutlinedInput-input {
    padding: 16px 5px 16px 16px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.share-pointer {
    cursor: pointer !important;
    margin-left: 12px !important;
}

.share-pointer .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    font-family: "Rubik", sans-serif !important;
    margin-left: 4px;
    color: #9B9FB1;
    min-width: 48px;
    text-align: left;
}

.ml-5 {
    margin-left: 5px !important;
    font-weight: 500 !important;
}

.sort-value-text {
    display: flex !important;
    align-items: center !important;
    color: var(--blue-grey-10, var(--base-blue-grey-10, #3C3E49)) !important;
    font-family: "Rubik", sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 0.16px !important;
    padding-top: 2px;
}

.p-16 {
    padding: 16px !important;
}

.error-message-text {
    color: var(--blue-grey-10, var(--base-blue-grey-10, #3C3E49)) !important;
    font-family: "Rubik", sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    margin-left: 12px !important;
}

.no-data-found {
    color: var(--blue-grey-10, var(--base-blue-grey-10, #3C3E49)) !important;
    font-family: "Rubik", sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 0;
    /* Make the scrollbar disappear */
}

.custom-scrollbar {
    scrollbar-width: thin;
    /* scrollbar-color: transparent transparent; */
}

.card-listing-wrap {
    margin-top: 15px;
}

.custom-chip-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: -5px;
}

.custom-chip-list .chip-item {
    padding: 4px 9px 4px 12px;
    display: flex;
    align-items: center;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    color: #3C3E49;
    text-align: center;
    background-color: #F3EBFF;
    border: 1px solid #B388FF;
    border-radius: 16px;
    margin-bottom: 5px;
}

.custom-chip-list .chip-item:not(:last-child) {
    margin-right: 12px;
}

.custom-chip-list .chip-item i {
    display: inline-flex;
    padding: 3px;
    margin-left: 9px;
    cursor: pointer;
}

.custom-chip-list .chip-item i img {
    height: 8px;
    width: 8px;
    object-fit: contain;
    object-position: center;
}

.filter-popup-wrapper {
    margin-right: 12px;
}

.filter-popup-wrapper .filter-popup-button {
    padding: 8px 24px;
    background-color: #fff;
    border: 1px solid rgba(179, 136, 255, 0.302);
    border-radius: 8px;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.6px;
    display: flex;
    align-items: center;
    text-transform: none !important;
}

.filter-popup-wrapper .filter-popup-button.open-popover {
    border-color: #B388FF;
    background-color: #F3EBFF;
}

.filter-popup-wrapper .filter-popup-button .filter-indicator {
    height: 4px;
    width: 4px;
    border-radius: 15px;
    background-color: #6200EA;
    position: relative;
    top: -2px;
    margin-left: 4px;
}

.filter-popup-wrapper .filter-popup-button .filter-ic {
    height: 16px;
    width: 16px;
    object-fit: contain;
    object-position: center;
    margin-right: 8px;
}

.filter-menu-popover {
    width: 300px;
    box-shadow: 0px 4px 16px 0px #00000026 !important;
    border-radius: 8px !important;
}

.filter-menu-popover .filter-category-wrap {
    padding: 0 7px;
}

.filter-option-wrapper .MuiAccordion-root {
    margin: 0 !important;
}

.filter-option-wrapper .MuiAccordion-root:before {
    display: none !important;
}

.filter-option-wrapper .MuiAccordion-root:not(:first-child) {
    border-top: 1px solid #ECEDF0;
}

.filter-option-wrapper .filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid #ECEDF0;
}

.filter-option-wrapper .filter-header .close-btn {
    padding: 6px;
    background: transparent !important;
    margin-right: -6px;
    margin-left: 18px;
}

.filter-option-wrapper .filter-header .close-btn img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    object-position: center;
}

.filter-option-wrapper .filter-header .title {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    letter-spacing: 0 !important;
    color: #3C3E49 !important;
}

.filter-option-wrapper .filter-header .title span {
    color: #651FFF;
}

.filter-option-wrapper .filter-header .clear-link {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0;
    color: #651FFF;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.filter-option-wrapper .filter-header .clear-link:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
}

.filter-option-wrapper .MuiAccordionSummary-root {
    padding: 18px 6px 18px 0 !important;
    transition: all 0.3s ease-in-out;
}

.filter-option-wrapper .MuiAccordionSummary-root:hover {
    background-color: #F8F9FA;
    transition: all 0.3s ease-in-out;
}

.filter-option-wrapper .MuiAccordionSummary-root .MuiIconButton-root img {
    height: 16px;
    width: 16px;
    object-fit: contain;
}

.filter-option-wrapper .MuiAccordionSummary-root .MuiTypography-root {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: 0 !important;
}

.filter-option-wrapper .MuiCollapse-root .MuiCollapse-wrapper {
    padding: 0 16px 15px !important;
}

.filter-option-wrapper .MuiCollapse-root .MuiCollapse-wrapper {
    padding-top: 0 !important;
}

.filter-option-wrapper .checkbox-group {
    padding: 0 10px;
}

.filter-option-wrapper .checkbox-group li .MuiCheckbox-root {
    padding: 6px !important;
}

.filter-option-wrapper .checkbox-group li .MuiTypography-root {
    padding-left: 3px;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: 0 !important;
    transition: color 0.2s ease-in-out;
}

.filter-option-wrapper .checkbox-group li .MuiFormControlLabel-label:hover {
    color: #651FFF;
    transition: color 0.2s ease-in-out;
}

.suggestion-list .MuiListItem-root.list-item-text .title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    color: #595959;
    margin: 0;
}

.suggestion-list .MuiListItem-root.list-item-text .subtext {
    font-size: 12px !important;
    font-weight: 400;
    line-height: 16px !important;
    letter-spacing: 0.04px;
    color: #595959;
    margin: 0;
}

@media (min-width:1920px) {
    .grid-even-asset .card-wrap {
        flex: 0 0 20% !important;
        max-width: 20% !important;
    }
}

.btn-r{
    background-image: url('../../assets/btnGray.svg');
    height: 35px;
    width: 35px;
    background-repeat: no-repeat;
    background-position: center;
}

.btn-l{
    background-image: url('../../assets/btnLeftGray.svg');
    height: 35px;
    width: 35px;
    background-repeat: no-repeat;
    background-position: center;
}

.btn-r:hover{
    background-image: url('../../assets/btn.svg');
}

.btn-l:hover{
    background-image: url('../../assets/btnLeft.svg');
}

.btn{
    box-shadow: 0px 4px 16px 0px #00000026 !important;
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    border-radius: 100% !important;
    text-align: center !important;
    padding: 13px 14px !important;
}